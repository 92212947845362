#help {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.8);
	padding: 0;
	margin: 0;
	z-index: 5;
}

#help dialog {
	display: block;
	background: #292929;
	color: #d0d0d0;
	max-width: 600px;
	width: 80%;
	margin: 20px auto auto auto;
	padding: 10px;
	border: none;
	border-radius: 5px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
}

#help #fail {
	display: block;
	background: #a00;
	margin: 0;
	padding: 5px;
}

#help article h1 {
	margin: 0 0 10px 0;
}

#help .button {
	font-weight: bold;
	font-size: 120%;
}
