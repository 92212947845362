
/*
	Links
*/

#sections {
	list-style: none;
	padding: 0;
	margin: 0;
	clear: both;
	display: block;
}

#sections li {
	list-style: none;
	padding: 0;
	margin: 1em 0;
}

.sim_container {
	margin: 0;
	padding: 0 0;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	list-style: none;
}

.sim_container > li {
	list-style: none;
	margin: 0 !important;
	padding: 0;
}

.sim {
	position: relative;
	display: inline-block;
	width: 250px;
	padding: 0;
	margin: 10px 20px 10px 0;

	border-radius: 5px;
	color: #d0d0d0;
	background: #222;
	overflow: hidden;
	text-decoration: none;

	transition: transform 200ms ease;

	.image {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 90px;

		img {
			object-fit: cover;
			margin: 0;
			padding: 0;
			width: 100%;
			height: 90px;
		}
	}

	h3 {
		margin: 0;
		padding: 10px;
		font-size: 110%;
		display: block;
	}

	.sim_desc {
		margin: 10px;
		color: #c0c0c0;
		padding: 0;
		min-height: 92px;
	}

	.image {
		display: none;
	}

	&:hover {
		box-shadow: 0 0 20px #000;
		transform: scale(1.05, 1.05);
		z-index: 2;
	}
}

@media (max-width: 550px) {
	.sim_container {
		display: block;
	}
	#sections h2 {
		padding: 10px;
	}
	.sim {
		height: auto;
		margin: 10px;
		width: auto;
		display: block;
	}
	#container {
		padding: 5px;
	}
}
