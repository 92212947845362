input {
	-webkit-touch-callout: text;
	-webkit-user-select: text;
	-khtml-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

input, select {
	border: 1px solid #333;
	background: #222;
	color: #f0f0f0;
	border-radius: 3px;
	padding: 3px;
}

#buttons {
	position: fixed;
	bottom: 10px;
	left: 10px;
}

.buttonset {
	list-style: none;
	margin: 0;
	padding: 0;
}
.buttonset li {
	margin: 0;
	padding: 0 10px 0 0;
	display: inline-block;
}

.buttonset a, .button {
	display: block;
	padding: 3px 1em 3px 1em;
	border: 1px solid #333;
	background: #222;
	color: #f0f0f0;
	border-radius: 3px;
	text-decoration: none;
}

.inline_button {
	display: inline-block;
}

.buttonset a:hover, .button:hover {
	cursor: pointer;
	background: #3A3A3A;
}

.light_mode .button, .light_mode .buttonset li a {
	background: none;
	color: black !important;
}

.light_mode input, .light_mode select {
	border: 1px solid black;
	background: white;
	color: black;
}

.alert {
	margin: 1em 0;
	padding: 0.75em 1em;
	border: 1px solid #333;
	border-radius: 0.5em;
	color: rgba(255, 255, 255, 0.65);
}
