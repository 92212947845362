html, body{
	margin: 0;
	padding: 0;
	font-family: "Roboto", sans-serif;
	background: black;
	color: #f0f0f0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

a {
	color: #f0f0f0;
	text-decoration: underline;
}

a:hover {
	color: white;
}

.light_mode a {
	color: black;
	text-decoration: underline;
}

.light_mode a:hover {
	color: blue;
}

#logo {
	position: fixed;
	top: 10px;
	right: 10px;
}
#logo img {
	width: 32px;
}

#canvas{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: none;
}

#bottom_bar {
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 5px;
	z-index: 10;
}

.light_mode, .light_mode #canvas {
	background: white !important;
	color: black;
}

.light_mode p {
	color: black;
}

.light_mode #logo {
	display: none;
}

h1 {
	margin-top: 0;
}

#logo {
	float: left;
	margin: 10px;
	width: 110px;
}

#fail {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: linear-gradient(to bottom, #ed213a, #93291e);
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
	padding: 1em;
	display: block;
	color: white;
	text-align: center;
	margin: 0;

	a {
		color: white;
	}
	a:hover {
		color: lightcoral;
	}
}