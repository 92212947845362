#sidebar {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 180px;
	padding: 10px;
	text-align: center;
	display: none;
}

.sidebar-shown {
	#sidebar {
		display: block;
	}

	#canvas {
		left: 200px;
	}

	#bottom_panel {
		position: fixed;
		left: 10px;
		bottom:-20px;
		width: 180px;
	}

	#bottom_panel a {
		margin: 5px 0 0 0;
	}
}

#sidebar h1 {
	font-size: 20px;
	margin: 0;
}

#sidebar h2 {
	font-size: 12px;
	margin: 0 0 10px 0;
}

#sidebar div {
	text-align: center;
	padding: 1em 0 2em 0;
}

#sidebar input {
	max-width: 100px;
}

#sidebar a {
	color: #f0f0f0;
}

#sidebar a :hover{
	color: white;
}

.light_mode #sidebar {
	background: white;
	color: black;
}

.light_mode a {
	color: black !important;
}
