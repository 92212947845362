header {
	padding: 2.5em 0;
	background: linear-gradient(to right, #642b73, #c6426e);
	margin: 0 0 3em 0;

	h1 {
		margin: 0;
	}
}

footer {
	margin: 0;
	max-width: none;
	padding: 3em 0;
	color: #666;
	text-align: center;

	a {
		color: #999;
		text-decoration: none;
	}

	a:hover {
		text-decoration: underline;
	}
}

.container {
	width: 80%;
	max-width: 1100px;
	margin: auto;
	padding: none;
}

@media (max-width:600px) {
	.container {
		width: 100%;
		margin: 0.3em;
	}
}

h2 {
	margin: 1.2em 0 0.6em 0;
	padding: 0;
}

#course-selector {
	margin-top: 1.4em;

	a {
		padding: 3px 5px;
		border: 1px solid rgba(255, 255, 255, 0.3);
		border-radius: 5px;
		text-decoration: none;
		margin: 0 3px;
	}

	a.selected, a:hover {
		border: 1px solid rgba(255, 255, 255, 0.6);
		background: rgba(255, 255, 255, 0.1);
	}
}